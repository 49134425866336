//------------------

import { PaginationInfo } from "app-types";

// Handle a blob/arraybuffer response and trigger the download
const CD_HEADER = "content-disposition";
const CD_HEADER_CAP = "Content-Disposition";    //historically, it has come back both ways

export async function doFetch(url: string, method: string, body: any) {

  try {
    const result = await fetch(url, { method: method, body: body });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}


export async function doFetchWithToken(url: string, method: string, body: any, token: string) {

  try {
    const headers = {
      //Azure Static Web apps currently don't support passing the 'authorization' header through the proxy to the Azure Function app. They 
      //use this header name for their own purposes.
      //So we make up our own.
      "x-personicom-auth": "Bearer " + token
    };

    const result = await fetch(url, { method: method, body, headers });
    if (result.json) {
      const json = await result.json();

      if (!result.ok) {
        return {
          isError: true,
          status: result.status,
          message: result.statusText,
          ...json
        };
      }

      return json;  //all good, just return the result
    }
    else if (result.ok === true) {
      //Nothing to return, but all is good
      return result;
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      message: errMsg
    };
  }
}

export async function doFileFetch(url: string, method: string, body: any, contentType: string, prepareResult: any) {
  try {
    const result = await fetch(url, { method: method, body });
    if (result.ok) {
      const prepared = prepareResult(result);
      const isOk = await downloadFile(prepared, contentType);
      if (!isOk) {
        return {
          isError: true,
          // status: result.status,
          message: result.statusText,
          ...result,
        };
      }
      else {
        return {
          isError: false,
          ...result,
        }
      }
    }
    else {
      return {
        isError: true,
        error: result.statusText || "Failed to fetch file for download",
        ...result,
      }
    }
  }
  catch (err) {
    let errMsg = "Unhandled exception during fetch. Our technical team has been notified.";
    if (err.text) errMsg = await err.text();

    return {
      isError: true,
      error: errMsg
    };
  }
}

export async function doFileFetchWithToken(
  url: string,
  method: string,
  body: any,
  contentType: string,
  prepareResult: any,
  token: string
) {
  try {
    const headers = {
      "x-personicom-auth": `Bearer ${token}`,
    };

    const response = await fetch(url, { method, body, headers });

    if (!response.ok) {
      return {
        isError: true,
        message: response.statusText || "Failed to fetch file for download",
        status: response.status,
        headers: response.headers,
        url: response.url,
        redirected: response.redirected
      };
    }

    const prepared = prepareResult(response);
    const isDownloadSuccessful = await downloadFile(prepared, contentType);

    if (!isDownloadSuccessful) {
      return {
        isError: true,
        message: "File download failed",
        status: response.status,
        headers: response.headers,
        url: response.url,
        redirected: response.redirected
      };
    }

    return { isError: false, ...response };

  } catch (error) {
    let errorMessage = "Unhandled exception during file fetch";
    // Safely check if error is an instance of Error
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    console.error("Error in doFileFetchWithToken:", error);

    return {
      isError: true,
      message: errorMessage,
    };
  }
}

export async function downloadFile(result: Response, mimeType: string) {
  const contentDisposition = result.headers.get("Content-Disposition");

  if (!contentDisposition) {
    console.error("No Content-Disposition header found.");
    return false;
  }

  let fileName = "downloaded_file"; // Fallback filename
  const cdParts = contentDisposition.split(';');
  const fnamePart = cdParts.find(part => part.trim().startsWith("filename="));

  if (fnamePart) {
    fileName = fnamePart.split("=")[1].trim().replace(/(^"|"$)/g, ''); // Remove quotes if present
  }

  try {
    const data = await result.blob();
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);

    const linkElement = document.createElement('a');
    linkElement.href = url;
    linkElement.download = fileName;

    // Append the link element to the DOM and simulate a click
    document.body.appendChild(linkElement);
    linkElement.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(linkElement);

    return true; // Download successful
  } catch (error) {
    console.error("Error during file download:", error);
    return false; // Download failed
  }
}


export function parsePagination(data: Record<string, any>){
  const pagination : PaginationInfo = {
    hasNextPage: data.hasNextPage,
    hasPreviousPage: data.hasPreviousPage,
    pageIndex: data.pageIndex,
    totalCount: data.totalCount,
    totalPages: data.totalPages,
    filter: data.filter,
    sort: data.sort,
  };

  return pagination;
}