import { ReportRow } from 'app-types';
import { ITimelineEvent } from "../report-timeline/report-timeline-types";
import { GridColDef } from "@material-ui/x-grid";
import { ReportGridColumn, ReportGridConfig } from "app-types";
import { playerUrlFormatter, dateFormatter, jsonCombineFormatter, jsonFormatter, coalesceFormatter, LinkCell, ReportStatus, TipCell } from "components/xgrid-helpers";
import { addDays, addMonths, addYears, endOfDay, format } from "date-fns";
import { doFetch, doFetchWithToken, doFileFetch, doFileFetchWithToken } from "helpers/api-helpers";
import { DateWithoutTz } from "helpers/general-helpers";
import { get, isString, keys, pick, reduce } from "lodash";
import { addWeeks } from 'date-fns/esm';

const DATE_FORMAT = "M/d/yy h:mm:ss aaa";

//---
//Formats a date column
export const formatDate = (value: any, withoutTz = true) => {
  if(value){
    const loc = withoutTz ? DateWithoutTz(value.toString()) : new Date(value.toString());  //moment(v).local();
    return format(loc, DATE_FORMAT);
  }

  return value;
}

export const dayStart = (dateValue: Date) => {
  const theStart = new Date(dateValue.toDateString());
  return theStart;
}

export const dayEnd = (dateValue: Date) => {
  const theStart = dayStart(dateValue);
  const theEnd = endOfDay(theStart);
  return theEnd;
}

export const startOrEndOfDay = (dateValue: Date, offset: string) => {
  const soed = (offset === "end" ? dayEnd(dateValue) : dayStart(dateValue));
  return soed;
}

const reflectDate = (v:Date) => v;

export const parseDateProp = (value: string | undefined) : Record<string, any> | undefined => {
  if(!value) return undefined;

  const parts = value.split(".");
  let num = parseInt(parts[0].slice(1));
  const sign = parts[0][0];
  const adjustFunc = num === 0 ? reflectDate : parts[1] === "days" ? addDays : (parts[1] === "weeks" ? addWeeks : (parts[1] === "months" ? addMonths : addYears));
  const offset = parts.length === 3 ? parts[2] : "start";

  if(sign === "-") num *= -1;

  return {
    adjustFunc,
    num,
    offset,
  };
}

//-- Converts a relative date string in the reporting.json filter object to an actual date.
// date string will be in the form of +/-[num].[period].[offset]  So +0.days.start is start of today, -1.days.end is end of yesterday, +1.months.start is start of 1 month from today.
export const getDateFromProperty = (value: string | undefined) : Date | undefined => {
  const prop = parseDateProp(value);
  if(!prop) return undefined;
  
  const baseDate = startOrEndOfDay(new Date(), prop.offset);
  const result = prop.adjustFunc(baseDate, prop.num);
  
  // if(!value) return undefined;

  // const parts = value.split(".");
  // let num = parseInt(parts[0].slice(1));
  // const sign = parts[0][0];
  // const adjustFunc = num === 0 ? (v:Date) => v : parts[1] === "days" ? addDays : (parts[1] === "weeks" ? addWeeks : (parts[1] === "months" ? addMonths : addYears));
  // const offset = parts.length === 3 ? parts[2] : "start";

  // if(sign === "-") num *= -1;
  // const baseDate = startOrEndOfDay(new Date(), offset);
  // const result = adjustFunc(baseDate, num);
  return result;
}


//-----
// Splits apart the filter string from the url
export function parseFilter(filterString: string) : Record<string, any>{
  if(!filterString) return {};
  const filters     = filterString.split("|");
  
  const filterObj   = reduce(filters, (result: Record<string, any>, value: any) => { 
    const parts = value.split("~"); 
    return {...result, [parts[0]]: parts[1]}
  }, {}) as Record<string, any>;

  return filterObj || {};
}

type FilterFunc = (value: any) => string;

//-----
// Gets a value, whether it's based on a function or just string property
export function getFilterValue(filterProp: string | FilterFunc | undefined, defaultValue: string | undefined = undefined) : string {
  let result = defaultValue ?? "";
  if(!filterProp){
    result = "";
  }
  else if (isString(filterProp)){
    result = filterProp as string;
  }
  // else if(isFunction(filterProp)){
  //   return (filterProp as FilterFunc)(data);
  // }  

  return result;
}

const colProps = ["field", "headerName", "hide", "width", "sortable", "type"];

const buildColumn = (col: ReportGridColumn, environment:string, client:string, subdomain:string) : GridColDef => {
  const partial = pick(col, colProps) as GridColDef;

  //HACK: So that all playerUrl fields default to this now....
  if(col.field == 'playerUrl')
  {
    partial.valueFormatter = playerUrlFormatter(environment, client, subdomain);
  }

  if(col.formatter){
    switch(col.formatter){
      case "date":
        partial.valueFormatter = dateFormatter;
        break;
      case "playerUrl":
        partial.valueFormatter = playerUrlFormatter(environment, client, subdomain);
        break;
      case "json":
        if(!col.formatterArgs) throw new Error("formatterArgs required for formatter type: json");
        partial.valueFormatter = jsonFormatter(col.formatterArgs);
        break;

      case "jsonCombine":
        if(!col.formatterArgs) throw new Error("formatterArgs required for formatter type: jsonCombine");
        partial.valueFormatter = jsonCombineFormatter(col.formatterArgs);
        break;

      case "coalesce":
        partial.valueFormatter = coalesceFormatter;
        break;
    }
  }

  if(col.renderType){
    switch(col.renderType){
      case "textWithTooltip":
        partial.renderCell = TipCell(col.renderArgs);
        break;

      case "link":
        // if(!col.renderArgs) throw new Error("renderArgs required for renderer type: link");
        partial.renderCell = LinkCell(col.renderArgs);
        break;

      case "status":
        partial.renderCell = ReportStatus(col.renderArgs);
        break;
    }
  }

  return partial;
}

///====
// Converts the grid configuration into columns to use with the x-grid
export const getGridColumns = (gridConfig: ReportGridConfig, environment:string, client:string, subdomain:string) : GridColDef[] => {

  let items : GridColDef[] = gridConfig.columns.map( col => buildColumn(col, environment, client, subdomain)); //col => {

  if(gridConfig.idField !== "id"){
    items = [{ field: "id", headerName: "Id", hide: true }, ...items];
  }

  return items;
}

//------------------
// Creates a query string out of the various pagination properties
export function buildQueryString(pageNum: number | null, pageSize: number | null, sort: string | undefined, filter: string | undefined, other: Record<string, string> | null = null) {
  let qs = new URLSearchParams("");
  if (pageNum !== null && pageSize) 
    qs.append("pageNumber", (pageNum + 1).toString());
  else
    qs.append("pageNumber", "1");

  if (pageSize) qs.append("pageSize", pageSize.toString());
  if (sort) qs.append("sort", encodeURIComponent(sort));
  if (filter) qs.append("filter", encodeURIComponent(filter));
  if(other){
    keys(other).forEach(k => {
      qs.append(k, other[k]);
    });
  }

  return qs.toString();
}

//-----
// Replaces the reportDetails of a ReportRow, and updates the array (immutably)
export const replaceItemDetails = (items: ReportRow[], renderId: string, itemEvents: ITimelineEvent[]) : ReportRow[] => {
  const toReplace = items.findIndex(i => i.renderId === renderId);
  if(toReplace === -1){
    return items;
  }

  const replacement = {
    ...items[toReplace],
    reportDetails: itemEvents,
  };

  let newArray = items.slice(0);
  newArray[toReplace] = replacement;
  return newArray;
}



const LOCAL_URL_BASE = "http://localhost:7071/api"; ///reports //{brand}/{subdomain}/videoanalytics";
// const url = "http://localhost:7071/api/videos/{brand}/{subdomain}?pageNumber={pageNumber}&pageSize={pageSize}";

export const getApiBaseUrl = (endpoint: string = "reports") => {
  const isLocalHost = Boolean(window.location.hostname.indexOf("localhost") > 0);
  if(isLocalHost){
    return endpoint ? `${LOCAL_URL_BASE}/${endpoint}` : LOCAL_URL_BASE;
  }
  else{
    return endpoint ? `/api/${endpoint}` : "/api";
  }
}

//----
//Gets the report data from the server
export const getReportData = async (client: string, subdomain: string, pageNum: number, pageSize: number, sort: string | undefined, filter: string | undefined, token: string | null) => {
  let other: Record<string, string> = {rootDomain: client};
  if(subdomain) other.subdomain = subdomain;

  const qs = buildQueryString(pageNum, pageSize, sort, filter, other);
  
  const fullUrl = `${getApiBaseUrl()}/videoanalytics?${qs}`;

  //const fullUrl =   `${getBaseUrl()}/${client}/${subdomain}/videoanalytics?${qs}`; 
  const response = token ? await doFetchWithToken(fullUrl, "GET", null, token) : await doFetch(fullUrl, "GET", null);
  //set the sort and filter into the response for retrieval later
  response.filter = filter;
  response.sort = sort;
  
  return response;
}

//----
//Gets the report details from the server
export const getReportDetails = async (client: string, subdomain: string, renderId: string, token: string | null) => {
  
  let other: Record<string, string> = {rootDomain: client};
  if(subdomain) other.subdomain = subdomain;
  const qs = buildQueryString(null, null, undefined, undefined, other);
  
  
  const fullUrl = `${getApiBaseUrl()}/videoanalytics/${renderId}?${qs}`
  // const fullUrl = (subdomain) ?      
  //   `${getApiBaseUrl()}/${client}/${subdomain}/videoanalytics/${renderId}`
  //  : `${getApiBaseUrl()}/${client}/videoanalytics/${renderId}`;
  
  const response = token ? await doFetchWithToken(fullUrl, "GET", null, token) : await doFetch(fullUrl, "GET", null);
  if(response && response.length === 0) return null;
  return response;
}

//---
// Downloads the report data as a csv using doFileFetchWithToken.
export const getReportDownload = async (
  brand: string,
  subdomain: string,
  sort: string | undefined,
  filter: string | undefined,
  token: string | null
) => {
  let other: Record<string, string> = { rootDomain: brand };
  if (subdomain) other.subdomain = subdomain;
  const qs = buildQueryString(null, null, sort, filter, other);

  const fullUrl = `${getApiBaseUrl()}/videoanalytics/export?${qs}`;

  // Prepare the response handling function
  const prepFunc = (response: Response) => {
    // Extract the filename from the Content-Disposition header returned by the backend
    const contentDisposition = response.headers.get("Content-Disposition");
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].trim() // Extract and trim the filename
      : "report_data.csv"; // Fallback to a default filename if none is provided

    const prepped: any = {
      headers: {
        get: (headerKey: string) => filename, // Use the extracted filename
      },
      blob: async () => await response.blob(), // Get the blob of the response
    };

    return prepped;
  };

  const response = token 
  ? await doFileFetchWithToken(fullUrl, "GET", null, "text/csv", prepFunc, token) 
  : await doFileFetch(fullUrl, "GET", null, "text/csv", prepFunc);

  // Return the result (for error handling or chaining)
  return response;
};


type ValueGettor = (prop: string, data: any) => string;
const EXPR = /{(.*?)}/g;

//----
//Parses and extracts a value from a data structure based on a template
// e.g. {prop1} will return data.prop1, or {prop.subPropValue} will return data.prop.subPropValue, etc.
export const getTemplateValue = (templateProperty: string, data: any, defaultValue: string = "", valueFunc?: ValueGettor) : string => {
  let output = templateProperty;
  let match = null;
  do{
    match = EXPR.exec(templateProperty);
    if(match){
      const val = valueFunc ? valueFunc(match[1], data) : get(data, match[1]);
      output = output.replace(match[0], val ?? "");
    }
  } while (match);
  
  return output || defaultValue;
}